import { createTheme, darkTheme, genPageTheme, shapes } from '@backstage/theme';

export const levvaTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#ffc600',
    },
    navigation: {
      ...darkTheme.palette.navigation,
      selectedColor: '#ffc600',
      indicator: '#ffc600',
    },
  },
  fontFamily: 'Capitana, sans-serif',
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#282829'], shape: shapes.wave }),
  },
});

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 25,
  },
  path: {
    fill: '#ffc600',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="334"
      height="60.962"
      viewBox="0 0 334 60.962"
    >
      <path
        className={classes.path}
        id="Caminho_3057"
        data-name="Caminho 3057"
        d="M1040.188,740.147h-25.965a17.581,17.581,0,0,1-17.556-17.566v-43.4h8.25v43.4a9.32,9.32,0,0,0,9.307,9.312h25.965Zm213.255-60.962-21.487,52.531h-3.17L1207.3,679.185h-10.007l24.5,60.962h17.154l24.5-60.962Zm-76,0-21.487,52.531h-3.17L1131.3,679.185H1121.29l24.5,60.962h17.154l24.5-60.962ZM1300.2,731.877c-11.287,0-18.581-5.691-18.581-14.5v-.935c0-6.481,5.532-7.842,10.172-7.842h29.253c.069,1.173.069,2.362.069,3.519,0,11.448-8.795,19.758-20.914,19.758m1.985-52.692h-24.275v8.268h22.756c9.892,0,17.229,5.4,19.347,14.164h-30.79c-9.932,0-16.1,5.1-16.1,13.305v1.986c0,13.681,11.038,23.237,26.844,23.237,18.074,0,30.7-12.053,30.7-29.312V708.5c0-17.532-11.444-29.312-28.478-29.312M1083.1,687.453c11.288,0,18.58,5.693,18.58,14.5v.935c0,6.481-5.531,7.841-10.171,7.841h-29.254c-.07-1.173-.07-2.362-.07-3.518,0-11.448,8.8-19.759,20.915-19.759m.234-8.268c-18.075,0-30.7,12.053-30.7,29.312v2.338c0,17.532,11.444,29.312,28.478,29.312h24.275v-8.27h-22.758c-9.89,0-17.227-5.4-19.345-14.163h30.79c9.932,0,16.1-5.1,16.1-13.306v-1.985c0-13.682-11.039-23.237-26.844-23.237"
        transform="translate(-996.667 -679.185)"
        fill="#ffc600"
      />
    </svg>
  );
};

export default LogoFull;
